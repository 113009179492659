import React, { FunctionComponent, useState } from 'react';
import styled from '@emotion/styled';

import { Box, makeStyles, Container } from '@material-ui/core';

import { getSectionMode, setSectionMode } from '../../../persistentStorage';
import { LanguageToggleContextContainer } from '../../LanguageToggleContextContainer';
import { normalizeId } from '../../../normalizeId';

import { withTextSelection } from '../../withTextSelection';
import { SmartCourseSectionNavigation } from './Sections/SmartCourseSectionNavigation';
import { SmartCourseSectionInfo } from './Sections/SmartCourseSectionInfo';
import { SmartCourseSectionSummaryContent } from './Sections/SmartCourseSectionSummaryContent';
import { SmartCourseSectionConversationContent } from './Sections/SmartCourseSectionConversationContent';
import { AddVocabSection } from './Sections/SmartCourseSectionAddVocab';

type Mode = 'conversation' | 'summary';

interface SmartCourseSectionPageProps {
  id: string;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sectionScreen: {
    width: '500px',
    borderRadius: '12px',
    background: 'white',
  },
  modeToggleBox: {
    display: 'flex',
    border: '1px solid #eee',
    marginBottom: '20px',
  },
});

interface ToggleButtonProps {
  isSelected: boolean;
}
const ToggleButton = styled.button<ToggleButtonProps>(
  ({ isSelected }) => `
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px 30px;
  flex-grow: 1;
  background: ${isSelected ? '#663399' : 'none'};
  color: ${isSelected ? 'white' : '#333'};
`,
);

const defaultMode = getSectionMode();

export const SmartCourseSectionPage: FunctionComponent<SmartCourseSectionPageProps> = props => {
  const [mode, setMode] = useState(defaultMode);
  const id = normalizeId(props.id, 'SmartCourseSection');
  const classes = useStyles();

  const onUpdateMode = (newMode: Mode) => {
    setSectionMode(newMode);
    setMode(newMode);
  };

  return (
    <Container className={classes.container}>
      <Box className={classes.sectionScreen}>
        <SmartCourseSectionNavigation
          sectionId={id}
        ></SmartCourseSectionNavigation>
        <SmartCourseSectionInfo sectionId={id}></SmartCourseSectionInfo>
        <LanguageToggleContextContainer>
          <Box className={classes.modeToggleBox}>
            <ToggleButton
              isSelected={mode === 'summary'}
              onClick={() => onUpdateMode('summary')}
            >
              Summary
            </ToggleButton>
            <ToggleButton
              isSelected={mode === 'conversation'}
              onClick={() => onUpdateMode('conversation')}
            >
              Conversation
            </ToggleButton>
          </Box>
          {mode === 'summary' && (
            <SmartCourseSectionSummaryContent
              sectionId={id}
            ></SmartCourseSectionSummaryContent>
          )}
          {mode === 'conversation' && (
            <SmartCourseSectionConversationContent
              sectionId={id}
            ></SmartCourseSectionConversationContent>
          )}
        </LanguageToggleContextContainer>
        <Box>
          <WrappedAddVocabSection sectionId={id} />
        </Box>
      </Box>
    </Container>
  );
};

const WrappedAddVocabSection = withTextSelection(AddVocabSection);
