import * as React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';

import Container from '../common/Container';
import { CoursewareCoursesQuery } from '../../generated/graphql';
import { GraphQLErrorDisplay } from '../GraphQLErrorDisplay';

const Level = styled.div``;
const LevelContainer = styled.div`
  display: flex;
  margin: 16px;
  flex-direction: column;
  flex-wrap: wrap;
`;

const useStyles = makeStyles(() => ({
  lessonsTd: {
    verticalAlign: 'baseline',
  },
}));

const coursewareCoursesQuery = gql`
  query CoursewareCourses {
    courses {
      id
      title
      cefrLevel
      lessons {
        id
        numberInCourse
      }
    }
  }
`;

const CoursewareTemplate = () => {
  const { loading, error, data } = useQuery<CoursewareCoursesQuery>(
    coursewareCoursesQuery,
  );
  const styles = useStyles();

  const renderInner = () => {
    if (loading) return 'Loading...';
    if (error) return <GraphQLErrorDisplay error={error} />;

    if (!data?.courses) return 'Not found :(';

    return (
      <table>
        <thead>
          {data.courses.map(course => (
            <td key={course?.id}>
              <h4>{course?.title}</h4>
              {course?.cefrLevel}
            </td>
          ))}
        </thead>
        <tbody>
          <tr>
            {data.courses.map(course => (
              <td key={course?.id} className={styles.lessonsTd}>
                {course?.lessons.map(
                  lesson =>
                    lesson && (
                      <Level key={lesson.id}>
                        <Link to={`/courseware/lesson/${lesson.id}`}>
                          Lesson {lesson.numberInCourse}
                        </Link>
                      </Level>
                    ),
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <Container>
      <h1>Courseware</h1>
      <LevelContainer>{renderInner()}</LevelContainer>
    </Container>
  );
};

export default CoursewareTemplate;
