import * as React from 'react';
import {
  Card,
  CardContent,
  Box,
  Typography,
  CardActions,
  Button,
} from '@material-ui/core';
import gql from 'graphql-tag';
import { Link } from 'gatsby';
import { DictionarySenseCardSenseFragment } from '../generated/graphql';

interface Props {
  sense: DictionarySenseCardSenseFragment;
  headerText?: string;
}

export const DictionarySenseCard: React.FC<Props> = ({ sense, headerText }) => {
  return (
    <Card>
      <CardContent>
        <Box mb={2}>
          <Typography color="textSecondary">{headerText}</Typography>
          <Typography variant="h5" component="h2">
            {sense.word.word}
          </Typography>
          <Typography color="textSecondary">{sense.pos}</Typography>
        </Box>
        <Typography variant="body2" component="p">
          {sense.definition}
        </Typography>
        <Box mt={2}>
          {sense.examples?.map(example => (
            <Box key={example.text}>
              <Typography variant="caption">{`"${example.text}"`}</Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
      <CardActions>
        <Link to={`/dictionary/sense/${sense.id}`}>
          <Button size="small">More</Button>
        </Link>
      </CardActions>
    </Card>
  );
};

export const dictionarySenseCardSenseFragment = gql`
  fragment dictionarySenseCardSense on DictionarySense {
    id
    definition
    pos
    examples {
      text
    }
    word {
      id
      word
    }
    adminEnhancements {
      source
      ... on TranslationsDictionaryEnhancement {
        translations {
          language
          translations
        }
      }
    }
  }
`;
