import { useQuery } from '@apollo/react-hooks';
import { Container, makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import gql from 'graphql-tag';
import flatten from 'lodash.flatten';
import React from 'react';
import { SmartCourseSectionNavQuery } from '../../../../generated/graphql';
import { capitalize, exists } from '../../../../util';
import { GraphQLErrorDisplay } from '../../../GraphQLErrorDisplay';

const useStyles = makeStyles(() => ({
  bottomNavContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '16px 0',
    fontSize: '14',
  },
}));

const smartCourseSectionNav = gql`
  query SmartCourseSectionNav {
    admin {
      smartCourses {
        id
        lessons {
          id
          sections {
            id
            sectionType
          }
        }
      }
    }
  }
`;

interface SmartCourseSectionNavigation {
  sectionId: string;
}

export const SmartCourseSectionNavigation = (
  props: SmartCourseSectionNavigation,
) => {
  const { loading, error, data } = useQuery<SmartCourseSectionNavQuery>(
    smartCourseSectionNav,
  );

  const classes = useStyles();

  const { sectionId } = props;

  if (error) return <GraphQLErrorDisplay error={error} />;
  if (loading)
    return (
      <Container className={classes.bottomNavContainer}>Loading...</Container>
    );

  // force typescript to be sane
  const flatSections = flatten(
    flatten(
      data?.admin?.smartCourses?.map(course =>
        course?.lessons.map(lesson => lesson.sections),
      ) || [],
    ).filter(exists),
  );

  const curSectionIndex = flatSections.findIndex(
    section => section.id === sectionId,
  );
  const nextId =
    curSectionIndex < flatSections.length - 1 &&
    flatSections[curSectionIndex + 1].id;
  const prevId = curSectionIndex > 0 && flatSections[curSectionIndex - 1].id;

  const curSectionType = flatSections[curSectionIndex].sectionType;
  const nextOfType = flatSections
    .slice(curSectionIndex + 1)
    .find(section => section.sectionType === curSectionType);

  return (
    <Container className={classes.bottomNavContainer}>
      <Link to="/smartCourse">Smart Courses</Link>
      {prevId && (
        <Link to={`/smartCourse/section/${prevId}`}>Previous Page</Link>
      )}
      {nextId && <Link to={`/smartCourse/section/${nextId}`}>Next Page</Link>}
      {nextOfType && curSectionType ? (
        <Link to={`/smartCourse/section/${nextOfType.id}`}>
          Next {capitalize(curSectionType)}
        </Link>
      ) : null}
    </Container>
  );
};
