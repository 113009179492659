import React, { FC } from 'react';
import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  CardHeader,
  Chip,
} from '@material-ui/core';
import { DateTime } from 'luxon';
import gql from 'graphql-tag';
import {
  PromoCodeInfoFragment,
  PromoCodeStatus,
} from '../../../generated/graphql';
import { RedeemPromoCodeDialog } from './RedeemPromoCodeDialog';
import { PromoCodeMenuButton } from './PromoCodeMenuButton';
import { UpdatePromoCodeDialog } from './ChangePromoCodeStatusDialog';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
  },
  cardTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  cardTitleStatusChip: {
    marginLeft: theme.spacing(1),
  },
  title: {
    fontSize: 14,
  },
  infoWrapper: {
    display: 'flex',
  },
  infoItemWrapper: {
    '&:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
  infoItemTitle: {
    marginTop: theme.spacing(1),
    fontSize: 14,
  },
}));

interface PromoCodeProps {
  info: PromoCodeInfoFragment;
}

const MENU_OPTIONS = ['Redeem', 'Change Status'] as const;

export const promoCodeInfoFragment = gql`
  fragment promoCodeInfo on PromoCode {
    id
    code
    maxRedemptions
    redemptionsRemaining
    title
    created
    product
    status
  }
`;

export const PromoCode: FC<PromoCodeProps> = props => {
  const [redeemCodeOpen, setRedeemCodeOpen] = React.useState(false);
  const [changeStatusOpen, setChangeStatusOpen] = React.useState(false);
  const styles = useStyles();
  const { info } = props;

  const onMenuOptionPress = (option: string) => {
    switch (option as typeof MENU_OPTIONS[number]) {
      case 'Redeem':
        setRedeemCodeOpen(true);
        break;
      case 'Change Status':
        setChangeStatusOpen(true);
        break;
      default:
        break;
    }
  };

  const closeRedeemDialog = () => {
    setRedeemCodeOpen(false);
  };

  const closeStatusUpdateDialog = () => {
    setChangeStatusOpen(false);
  };

  const title = (
    <div className={styles.cardTitleContainer}>
      <Typography variant="h5" component="h2">
        {info.title ?? 'Untitled'}
      </Typography>
      {info.status !== PromoCodeStatus.Active && (
        <Chip
          className={styles.cardTitleStatusChip}
          color="secondary"
          label={info.status}
        />
      )}
    </div>
  );

  return (
    <Card className={styles.container}>
      <CardHeader
        title={title}
        subheader={`Created ${DateTime.fromISO(info.created).toFormat(
          'DDD t',
        )}`}
        action={
          <PromoCodeMenuButton
            options={MENU_OPTIONS}
            onOptionPress={onMenuOptionPress}
          />
        }
      />

      <CardContent>
        <Typography className={styles.title} color="textSecondary" gutterBottom>
          Code
        </Typography>
        <Typography variant="h6" component="h2">
          {info.code}
        </Typography>
        <div className={styles.infoWrapper}>
          <div className={styles.infoItemWrapper}>
            <Typography className={styles.infoItemTitle} color="textSecondary">
              Product
            </Typography>
            <Typography variant="body2" component="p">
              {info.product}
            </Typography>
          </div>
          <div className={styles.infoItemWrapper}>
            <Typography className={styles.infoItemTitle} color="textSecondary">
              Max Redemptions
            </Typography>
            <Typography variant="body2" component="p">
              {info.maxRedemptions}
            </Typography>
          </div>
          <div className={styles.infoItemWrapper}>
            <Typography className={styles.infoItemTitle} color="textSecondary">
              Redemptions Remaining
            </Typography>
            <Typography variant="body2" component="p">
              {info.redemptionsRemaining}
            </Typography>
          </div>
        </div>
      </CardContent>
      <RedeemPromoCodeDialog
        open={redeemCodeOpen}
        code={info.code}
        onCloseClick={closeRedeemDialog}
      />
      <UpdatePromoCodeDialog
        open={changeStatusOpen}
        id={info.id}
        currentStatus={info.status}
        onCloseClick={closeStatusUpdateDialog}
      />
    </Card>
  );
};
