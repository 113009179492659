import React, { FC } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  FormGroup,
  TextField,
  DialogContentText,
} from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  AdminCreatePromoCodeBatchMutation,
  AdminCreatePromoCodeBatchMutationVariables,
  VoucherSubscriptionProduct,
} from '../../../generated/graphql';
import { GraphQLErrorDisplay } from '../../GraphQLErrorDisplay';

const PRODUCTS = [
  {
    title: 'Five Minutes',
    value: VoucherSubscriptionProduct.FiveMinutes,
  },
  {
    title: '1 Hour',
    value: VoucherSubscriptionProduct.OneHour,
  },
  {
    title: '12 Hours',
    value: VoucherSubscriptionProduct.TwelveHours,
  },
  {
    title: '7 Days',
    value: VoucherSubscriptionProduct.SevenDays,
  },
  {
    title: '1 Month',
    value: VoucherSubscriptionProduct.OneMonth,
  },
  {
    title: '3 Months',
    value: VoucherSubscriptionProduct.ThreeMonths,
  },
  {
    title: '6 Months',
    value: VoucherSubscriptionProduct.SixMonths,
  },
  {
    title: '1 Year',
    value: VoucherSubscriptionProduct.OneYear,
  },
  {
    title: 'Lifetime',
    value: VoucherSubscriptionProduct.Lifetime,
  },
] as const;

const useStyles = makeStyles(theme => ({
  productSelect: {
    marginBottom: theme.spacing(3),
    minWidth: 250,
  },
  confirmInput: {
    marginBottom: theme.spacing(1),
    minWidth: 500,
  },
}));

const adminCreatePromoCodeBatch = gql`
  mutation adminCreatePromoCodeBatch($input: AdminCreatePromoCodeBatchInput!) {
    adminCreatePromoCodeBatch(input: $input) {
      filename
    }
  }
`;

export const CreatePromoCodeBatchDialogButton: FC = () => {
  const styles = useStyles();
  const [open, setOpen] = React.useState(false);
  const [product, setProduct] = React.useState<VoucherSubscriptionProduct>(
    PRODUCTS[4].value,
  );
  const [reason, setReason] = React.useState<string | undefined>();
  const [title, setTitle] = React.useState<string | undefined>();
  const [amount, setAmount] = React.useState(1);
  const [expiresInMonths, setExpiresInMonths] = React.useState(1);

  const [createPromoCode, { data, error, loading }] = useMutation<
    AdminCreatePromoCodeBatchMutation,
    AdminCreatePromoCodeBatchMutationVariables
  >(adminCreatePromoCodeBatch);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    if (amount > 0 && reason && title && product) {
      createPromoCode({
        variables: {
          input: {
            maxRedemptions: 1,
            reason,
            title,
            productId: product,
            amount,
            expiresInMonths,
          },
        },
      });
    }
  };

  const handleChangeDuration = (evt: { target: { value: any } }) => {
    setProduct(evt.target.value);
  };

  const hasData = data && !loading;

  return (
    <div>
      <Button
        style={{ marginTop: 8, marginLeft: 8 }}
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
      >
        Create new Promo Code Batch
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create Promo Code</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill in the fields below to create a promo code batch. Each promo
            code will have the same title and reason. Give the promo code a
            title, and a reason for creating it. The batch will appear in the
            Google Drive.
          </DialogContentText>
          <FormGroup row={false}>
            <FormControl className={styles.productSelect}>
              <InputLabel id="product-select-label">Choose product</InputLabel>
              <Select
                labelId="product-select-label"
                id="product-select"
                value={product}
                onChange={handleChangeDuration}
              >
                {PRODUCTS.map(opt => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.title}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                autoFocus
                margin="dense"
                type="number"
                label="Amount"
                fullWidth
                value={amount}
                onChange={evt => {
                  const value = Number.parseInt(evt.target.value, 10);
                  if (value >= 1) setAmount(value);
                }}
              />
              <TextField
                autoFocus
                margin="dense"
                type="number"
                label="Months till codes expire"
                fullWidth
                value={expiresInMonths}
                onChange={evt => {
                  const value = Number.parseInt(evt.target.value, 10);
                  if (value >= 1) setExpiresInMonths(value);
                }}
              />
              <TextField
                margin="dense"
                label="Title of the promo code (i.e. 'Language', this title will be used for tracking)"
                fullWidth
                onChange={evt => setTitle(evt.target.value)}
              />
              <TextField
                margin="dense"
                label="Reason for the promo code"
                fullWidth
                onChange={evt => setReason(evt.target.value)}
              />
            </FormControl>
          </FormGroup>
          {data?.adminCreatePromoCodeBatch?.filename &&
            data?.adminCreatePromoCodeBatch?.filename}
        </DialogContent>
        <DialogActions>
          {hasData ? (
            <>
              <Button onClick={handleClose}>Close</Button>
            </>
          ) : (
            <>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleConfirm} disabled={hasData}>
                Create batch
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <GraphQLErrorDisplay error={error} />
    </div>
  );
};
