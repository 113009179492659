import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { colors } from '../../styles/variables';
import { BranchSelect } from './BranchSelect';

const INCLUDE_BRANCHES = !!process.env.BRANCH_API_HOST_BASE;

const query = graphql`
  query LayoutFooterQuery {
    versionInfo {
      version
    }
  }
`;

const useStyles = makeStyles(theme => ({
  footer: {
    fontSize: '11px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footerItem: {
    margin: theme.spacing(3),
    color: colors.gray.calm,
  },
}));

export const LayoutFooter: FC = () => {
  const styles = useStyles();
  return (
    <StaticQuery
      query={query}
      render={data => (
        <div className={styles.footer}>
          <div className={styles.footerItem}>v{data.versionInfo.version}</div>
          {INCLUDE_BRANCHES && <BranchSelect />}
        </div>
      )}
    />
  );
};
