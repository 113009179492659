import { useQuery } from '@apollo/react-hooks';
import { Link } from 'gatsby';
import gql from 'graphql-tag';
import React from 'react';
import { Box } from '@material-ui/core';

import { GraphQLErrorDisplay } from '../../../GraphQLErrorDisplay';
import { LoadSmartCourseSectionInfoQuery } from '../../../../generated/graphql';
import { CopyableID, InfoBox } from '../InfoBox';
import { getLanguageName } from '../../../common/getLanguageName';

const sectionInfoQuery = gql`
  query LoadSmartCourseSectionInfo($id: ID!) {
    node(id: $id) {
      id
      ... on SmartCourseSection {
        title
        description
        sectionType
        lesson {
          id
          title
          numberInCourse
          course {
            id
            title
            language
          }
        }
        vocabSuggestions
      }
    }
  }
`;

interface SmartCourseSectionInfoProps {
  sectionId: string;
}

export const SmartCourseSectionInfo = (props: SmartCourseSectionInfoProps) => {
  const { sectionId } = props;

  const { loading, error, data } = useQuery<LoadSmartCourseSectionInfoQuery>(
    sectionInfoQuery,
    { variables: { id: sectionId } },
  );
  if (error) return <GraphQLErrorDisplay error={error} />;

  if (
    !data ||
    !data.node ||
    data.node.__typename !== 'SmartCourseSection' ||
    data.node.id !== sectionId
  ) {
    if (loading) return <Box>Loading...</Box>;
    return <p>Not found :(</p>;
  }
  const sectionInfo = data.node;

  return (
    <>
      <InfoBox>
        <Box>
          <b>Course:</b>{' '}
          <Link to={`/smartCourse/${sectionInfo.lesson.course.id}`}>
            {sectionInfo.lesson.course.title}
          </Link>{' '}
          {getLanguageName(sectionInfo.lesson.course.language)}
        </Box>
        <Box>
          <b>Course ID:</b>
          <CopyableID>{sectionInfo.lesson.course.id}</CopyableID>
        </Box>
        <Box>
          <b>Lesson:</b>{' '}
          <Link to={`/smartCourse/lesson/${sectionInfo.lesson.id}`}>
            {sectionInfo.lesson.numberInCourse} - {sectionInfo.lesson.title}
          </Link>
        </Box>
        <Box>
          <b>Lesson ID:</b>
          <CopyableID>{sectionInfo.lesson.id}</CopyableID>
        </Box>
        <Box>
          <b>Section:</b>{' '}
          <Link to={`/smartCourse/section/${sectionInfo.id}`}>
            {sectionInfo.title}
          </Link>
        </Box>
        <Box>
          <b>Section ID:</b>
          <CopyableID>{sectionInfo.id}</CopyableID>
        </Box>
        <Box>
          <b>Section description:</b> {sectionInfo.description}
        </Box>
      </InfoBox>
      <h1>{sectionInfo.title}</h1>
    </>
  );
};
