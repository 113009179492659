import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React from 'react';
import { SmartCourseSectionLoadConversationQuery } from '../../../../generated/graphql';
import { Dialog, dialogFragment } from '../../../dialog/Dialog';
import { GraphQLErrorDisplay } from '../../../GraphQLErrorDisplay';
import { LeftHighlightBorderContainer } from '../../../LeftHighlightBorderContainer';
import { LocalizedText } from '../../../LocalizedText';

const smartCourseConversationQuery = gql`
  query SmartCourseSectionLoadConversation($id: ID!) {
    node(id: $id) {
      id
      ... on SmartCourseSection {
        content {
          theme {
            text
            localizedText
          }
          dialog {
            ...dialogFragment
          }
        }
      }
    }
  }

  ${dialogFragment}
`;

interface SmartCourseSectionConversationContentProps {
  sectionId: string;
}

export const SmartCourseSectionConversationContent = (
  props: SmartCourseSectionConversationContentProps,
) => {
  const { sectionId } = props;

  const { loading, error, data } = useQuery<
    SmartCourseSectionLoadConversationQuery
  >(smartCourseConversationQuery, {
    variables: { id: sectionId },
  });
  if (error) return <GraphQLErrorDisplay error={error} />;

  if (
    !data ||
    !data.node ||
    data.node.__typename !== 'SmartCourseSection' ||
    !data.node.content.dialog ||
    data.node.id !== sectionId
  ) {
    if (loading) return <p>Loading...</p>;
    return <p>Not found :(</p>;
  }

  const { content } = data.node;

  return (
    <>
      {content.theme ? (
        <p>
          Theme: <LocalizedText item={content.theme} />
        </p>
      ) : null}
      <LeftHighlightBorderContainer color="purple">
        <Dialog {...content.dialog} />
      </LeftHighlightBorderContainer>
    </>
  );
};
