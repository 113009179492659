import { useQuery } from '@apollo/react-hooks';
import { Box, Chip, Fab, Typography } from '@material-ui/core';
import { Add, AddBox } from '@material-ui/icons';
import gql from 'graphql-tag';
import React, { useEffect } from 'react';
import { AddVocabInfoToSmartCourseSectionQuery } from '../../../../generated/graphql';
import { AddVocabToLessonDialog } from '../../../AddVocabToLessonDialog';
import { GraphQLErrorDisplay } from '../../../GraphQLErrorDisplay';
import { VocabSuggestionChip } from '../../../VocabSuggestionChip';

const addVocabInfoToSectionQuery = gql`
  query AddVocabInfoToSmartCourseSection($id: ID!) {
    node(id: $id) {
      id
      ... on SmartCourseSection {
        lesson {
          id
        }
        vocabSuggestions
      }
    }
  }
`;

interface AddVocabSectionProps {
  sectionId: string;
  textSelection?: string;
}

export const AddVocabSection: React.FC<AddVocabSectionProps> = ({
  sectionId,
  textSelection,
}) => {
  const [vocabTaggingDialogOpen, setVocabTaggingDialogOpen] = React.useState(
    false,
  );

  const [
    vocabTaggingWordSuggestion,
    setVocabTaggingWordSuggestion,
  ] = React.useState<string | undefined>();

  const onSuggestionPressed = (suggestion: string) => {
    setVocabTaggingWordSuggestion(suggestion);
    setVocabTaggingDialogOpen(true);
  };

  const onVocabTaggingDialogClose = () => {
    setVocabTaggingDialogOpen(false);
    setVocabTaggingWordSuggestion(undefined);
  };

  useEffect(() => {
    if (vocabTaggingDialogOpen) return;

    setVocabTaggingWordSuggestion(textSelection);
  }, [textSelection]);

  const { error, data } = useQuery<AddVocabInfoToSmartCourseSectionQuery>(
    addVocabInfoToSectionQuery,
    { variables: { id: sectionId } },
  );
  if (error) return <GraphQLErrorDisplay error={error} />;

  const sectionInfo =
    data?.node?.__typename === 'SmartCourseSection' ? data.node : undefined;

  if (!sectionInfo) return null;

  return (
    <Box mt={4}>
      <Typography variant="h4">Tag Vocabulary</Typography>
      <Box mt={1} display="flex" flexDirection="row" flexWrap="wrap">
        {sectionInfo?.vocabSuggestions?.map(suggestion => (
          <Box key={suggestion} mr={1} mt={1}>
            <VocabSuggestionChip
              suggestion={suggestion}
              onClick={onSuggestionPressed}
            ></VocabSuggestionChip>
          </Box>
        ))}
        <Box mr={1} mt={1}>
          <Chip
            icon={<AddBox />}
            label="add new"
            onClick={() => setVocabTaggingDialogOpen(true)}
          />
        </Box>
      </Box>
      <AddVocabToLessonDialog
        open={vocabTaggingDialogOpen}
        onClose={onVocabTaggingDialogClose}
        lessonId={sectionInfo?.lesson.id}
        searchWord={vocabTaggingWordSuggestion}
      />
      {textSelection && (
        <Box position="fixed" right="32px" bottom="32px">
          <Fab
            variant="extended"
            onClick={() => setVocabTaggingDialogOpen(true)}
          >
            <Add />
            {`Tag "${textSelection}"`}
          </Fab>
        </Box>
      )}
    </Box>
  );
};
