import { UserAgentApplication } from 'msal';
import gql from 'graphql-tag';
import { print } from 'graphql';

const ACCESS_TOKEN = 'ACCESS_TOKEN';
const REFRESH_TOKEN = 'REFRESH_TOKEN';

let userAgentApplication: UserAgentApplication | undefined;

// only load redirect stuff if not in ssr, otherwise gatsby freaks out
if (typeof window !== 'undefined') {
  // create UserAgentApplication instance
  userAgentApplication = new UserAgentApplication({
    auth: {
      clientId: process.env.MS_OAUTH_CLIENT_ID as string,
      authority: `https://login.microsoftonline.com/${process.env.MS_OAUTH_TENANT_ID}`,
    },
  });
}

const refreshAccessTokenMutation = gql`
  mutation authRefreshAccessToken($input: AuthRefreshAccessTokenInput!) {
    authRefreshAccessToken(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const login = async (): Promise<void> => {
  if (!userAgentApplication) return;
  const { idToken } = await userAgentApplication.loginPopup();
  const res = await fetch(`${process.env.API_HOST}/admin-oauth`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ access_token: idToken.rawIdToken }),
  });
  const resJson = await res.json();

  if (resJson.error) {
    throw new Error(resJson.error);
  }
  if (!resJson.accessToken || !resJson.refreshToken) {
    throw new Error('Unable to sign in');
  }

  setAccessToken(resJson.accessToken);
  setRefreshToken(resJson.refreshToken);
};

export const refreshAccessToken = async () => {
  const response = await fetch(`${process.env.API_HOST}/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: print(refreshAccessTokenMutation),
      variables: {
        input: {
          refreshToken: getRefreshToken(),
        },
      },
    }),
  });

  const resJson = await response.json();

  if (resJson.errors) {
    clearAuthToken();

    throw new Error('Unable to refresh token, please relogin');
  }

  const { accessToken, refreshToken } = resJson.data.authRefreshAccessToken;

  if (!accessToken || !refreshToken) {
    clearAuthToken();

    throw new Error('Unable to refresh token, please relogin');
  }

  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
};

export const getRefreshToken = (): string | undefined =>
  localStorage.getItem(REFRESH_TOKEN) || undefined;

export const getAccessToken = (): string | undefined =>
  localStorage.getItem(ACCESS_TOKEN) || undefined;

const setAccessToken = (accessToken: string): void =>
  localStorage.setItem(ACCESS_TOKEN, accessToken);

const setRefreshToken = (refreshToken: string): void =>
  localStorage.setItem(REFRESH_TOKEN, refreshToken);

export const clearAuthToken = (): void => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
};

export const isAuthenticated = () =>
  !!(typeof localStorage !== 'undefined' && getAccessToken());
