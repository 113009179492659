import gql from 'graphql-tag';
import * as React from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';

import { Search } from '@material-ui/icons';
import Container from '../common/Container';
import {
  DictionarySenseCard,
  dictionarySenseCardSenseFragment,
} from '../DictionarySenseCard';
import { DictionarySearchPageSearchQuery } from '../../generated/graphql';

export const DictionarySearchPage = () => {
  const [word, setWord] = React.useState<string | undefined>();
  const [search, { data }] = useLazyQuery<DictionarySearchPageSearchQuery>(
    dictionarySearchPageSearchQuery,
  );

  const searchWord = (evt: React.FormEvent) => {
    evt.preventDefault();
    search({ variables: { word } });
  };

  return (
    <Container>
      <Typography variant="h1">Dictionary Search</Typography>
      <Box mt={1}>
        <form onSubmit={searchWord}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              id="search-input"
              aria-describedby="helper-text"
              type="search"
              onChange={evt => setWord(evt.target.value)}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <FormHelperText id="helper-text">
              Search for a word in the dictionary
            </FormHelperText>
          </FormControl>
        </form>
      </Box>
      {data?.admin?.adminDictionaryLookup.map(result =>
        result.senses.map(sense => (
          <Box marginY={1} key={sense.id}>
            <DictionarySenseCard sense={sense} />
          </Box>
        )),
      )}
    </Container>
  );
};

const dictionarySearchPageSearchQuery = gql`
  query dictionarySearchPageSearch($word: String!, $language: String) {
    admin {
      adminDictionaryLookup(word: $word, language: $language) {
        id
        word
        senses {
          ...dictionarySenseCardSense
        }
      }
    }
  }
  ${dictionarySenseCardSenseFragment}
`;
