import * as React from 'react';
import gql from 'graphql-tag';
import queryString from 'query-string';
import { useQuery } from '@apollo/react-hooks';
import {
  Container,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import { GraphQLErrorDisplay } from '../../GraphQLErrorDisplay';
import {
  AdminSmartCoursesQuery,
  SmartCourseDifficulty,
  StudyLanguage,
} from '../../../generated/graphql';
import { SmartCourseFilterDifficulty } from './SmartCourseFilterDifficulty';
import { SmartCourseFilterTitle } from './SmartCourseFilterTitle';
import { SmartCourseFilterLanguage } from './SmartCourseFilterLanguage';
import {
  SmartCourseTableRow,
  smartCourseTableRowSmartCourseFragment,
} from './SmartCourseTableRow';

const useStyles = makeStyles(() => ({
  lessonsTd: {
    verticalAlign: 'baseline',
  },
  levelContainer: {
    display: 'flex',
    margin: 16,
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
}));

const smartCoursesQuery = gql`
  query AdminSmartCourses {
    admin {
      smartCourses {
        id
        title
        difficulty
        language
        ...smartCourseTableRowSmartCourse
      }
    }
  }
  ${smartCourseTableRowSmartCourseFragment}
`;

type SmartCourseIndexPageAdmin = NonNullable<AdminSmartCoursesQuery['admin']>;

type SmartCourseIndexPageSmartCourse = NonNullable<
  SmartCourseIndexPageAdmin['smartCourses']
>[0];

const filterSmartCourse = (
  smartCourse: SmartCourseIndexPageSmartCourse,
  filteredLanguage: StudyLanguage,
  filteredDifficulty: SmartCourseDifficulty[],
  filteredTitle: string,
): boolean => {
  if (
    filteredDifficulty.length > 0 &&
    !filteredDifficulty.includes(smartCourse.difficulty)
  ) {
    return false;
  }

  if (
    smartCourse.title.toLowerCase().search(filteredTitle.toLowerCase()) === -1
  ) {
    return false;
  }

  if (smartCourse.language !== filteredLanguage) return false;

  return true;
};

export const SmartCourseIndexPage = () => {
  const { lang } = queryString.parse(window.location.search);

  const { loading, error, data } = useQuery<AdminSmartCoursesQuery>(
    smartCoursesQuery,
  );
  const classes = useStyles();

  const [filteredDifficulty, setFilteredDifficulty] = React.useState<
    SmartCourseDifficulty[]
  >([]);
  const [filteredLanguage, setFilteredLanguage] = React.useState<StudyLanguage>(
    ((lang as string)?.toUpperCase() as StudyLanguage) || StudyLanguage.En,
  );

  const [filteredTitle, setFilteredTitle] = React.useState<string>('');

  const renderInner = () => {
    if (loading) return 'Loading...';
    if (error) return <GraphQLErrorDisplay error={error} />;

    const handleDifficultyChange = (
      event: React.ChangeEvent<{ value: unknown }>,
    ) => {
      setFilteredDifficulty(event.target.value as SmartCourseDifficulty[]);
    };

    const handleTitleChange = (event: React.ChangeEvent<{ value: string }>) => {
      setFilteredTitle(event.target.value as string);
    };

    const handleLanguageChange = (
      event: React.ChangeEvent<{ value: unknown }>,
    ) => {
      const language = event.target.value as StudyLanguage;
      setFilteredLanguage(language);
      window.history.replaceState(
        undefined,
        'Teacher Port',
        `/smartCourse?lang=${language.toLowerCase()}`,
      );
    };

    if (!data?.admin?.smartCourses) return 'Not found :(';

    return (
      <>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  <Typography variant="h5">Title</Typography>
                  <SmartCourseFilterTitle
                    filteredTitle={filteredTitle}
                    onChange={handleTitleChange}
                  ></SmartCourseFilterTitle>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h5">Language</Typography>
                  <SmartCourseFilterLanguage
                    language={filteredLanguage}
                    onChange={handleLanguageChange}
                  />
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h5">Difficulty</Typography>
                  <SmartCourseFilterDifficulty
                    filteredDifficulty={filteredDifficulty}
                    onChange={handleDifficultyChange}
                  ></SmartCourseFilterDifficulty>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.admin.smartCourses.map(smartCourse => {
                if (
                  !filterSmartCourse(
                    smartCourse,
                    filteredLanguage,
                    filteredDifficulty,
                    filteredTitle,
                  )
                ) {
                  return null;
                }
                return (
                  <SmartCourseTableRow
                    key={smartCourse.id}
                    smartCourse={smartCourse}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  return (
    <Container>
      <h1>Smart Courses</h1>
      <Container className={classes.levelContainer}>{renderInner()}</Container>
    </Container>
  );
};
