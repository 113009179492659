const SECTION_MODE_KEY = 'DEFAULT_SECTION_MODE';
const SECONDARY_LANGUAGE_KEY = 'DEFAULT_SECONDARY_LANGUAGE';
const LOCALIZE_CONTENT_KEY = 'DEFAULT_LOCALIZE_CONTENT';
const API_REGION_KEY = 'API_REGION';

export const SECONDARY_LANGUAGES = [
  'de',
  'es',
  'fr',
  'it',
  'ja',
  'ko',
  'pt',
  'ru',
  'th',
  'tr',
  'zh-hans',
  'zh-hant',
] as const;

export type SectionMode = 'conversation' | 'summary';
export type ApiRegion = 'china' | 'global';
export type SecondaryLanguage = typeof SECONDARY_LANGUAGES[number];

export const getSecondaryLanguage = (): SecondaryLanguage => {
  if (
    typeof localStorage !== 'undefined' &&
    SECONDARY_LANGUAGES.includes(
      localStorage.getItem(SECONDARY_LANGUAGE_KEY) as SecondaryLanguage,
    )
  ) {
    return localStorage.getItem(SECONDARY_LANGUAGE_KEY) as SecondaryLanguage;
  }
  return 'zh-hans';
};

export const setSecondaryLanguage = (lang: SecondaryLanguage): void => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(SECONDARY_LANGUAGE_KEY, lang);
  }
};

export const getLocalizeContent = (): boolean => {
  return (
    typeof localStorage !== 'undefined' &&
    localStorage.getItem(LOCALIZE_CONTENT_KEY) === 'TRUE'
  );
};

export const setLocalizeContent = (localizeContent: boolean): void => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(
      LOCALIZE_CONTENT_KEY,
      localizeContent ? 'TRUE' : 'FALSE',
    );
  }
};

export const getSectionMode = (): SectionMode => {
  return typeof localStorage !== 'undefined' &&
    localStorage.getItem(SECTION_MODE_KEY) === 'summary'
    ? 'summary'
    : 'conversation';
};

export const setSectionMode = (mode: SectionMode): void => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(SECTION_MODE_KEY, mode);
  }
};

export const getApiRegion = (): ApiRegion | undefined => {
  return (
    (typeof localStorage !== 'undefined' &&
      (localStorage.getItem(API_REGION_KEY) as ApiRegion)) ||
    undefined
  );
};

export const setApiRegion = (region: ApiRegion): void => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(API_REGION_KEY, region);
  }
};
