import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Typography,
  Table,
  TableBody,
} from '@material-ui/core';
import { Link } from 'gatsby';
import React, { FC } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import gql from 'graphql-tag';
import { SmartCourseTableRowSmartCourseFragment } from '../../../generated/graphql';
import { getLanguageName } from '../../common/getLanguageName';

export const smartCourseTableRowSmartCourseFragment = gql`
  fragment smartCourseTableRowSmartCourse on SmartCourse {
    id
    title
    difficulty
    language
    lessons {
      id
      title
      numberInCourse
    }
  }
`;

interface SmartCourseTableRowProps {
  smartCourse: SmartCourseTableRowSmartCourseFragment;
}

export const SmartCourseTableRow: FC<SmartCourseTableRowProps> = props => {
  const { smartCourse } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Link to={`/smartCourse/${smartCourse.id}`}>{smartCourse.title}</Link>
        </TableCell>
        <TableCell align="right">
          {getLanguageName(smartCourse.language)}
        </TableCell>
        <TableCell align="right">{smartCourse.difficulty}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Lessons
              </Typography>
              <Table size="small" aria-label="lessons">
                <TableBody>
                  {smartCourse.lessons.map(lesson => (
                    <TableRow key={lesson.id}>
                      <TableCell component="th" scope="row">
                        <Link to={`/smartCourse/lesson/${lesson.id}`}>
                          Lesson {lesson.numberInCourse}
                        </Link>
                      </TableCell>
                      <TableCell>{lesson.title}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
