import * as React from 'react';
import { Link } from 'gatsby';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Typography,
} from '@material-ui/core';

import Container from '../../common/Container';
import { SmartCourseQuery } from '../../../generated/graphql';
import { normalizeId } from '../../../normalizeId';
import { GraphQLErrorDisplay } from '../../GraphQLErrorDisplay';
import { CopyableID, InfoBox } from './InfoBox';
import { getLanguageName } from '../../common/getLanguageName';

const smartCourseQuery = gql`
  query SmartCourse($id: ID!) {
    node(id: $id) {
      ... on SmartCourse {
        id
        title
        language
        difficulty
        topics
        lessons {
          id
          title
          numberInCourse
        }
      }
    }
  }
`;

interface Props {
  id: string;
}

export const SmartCoursePage = (props: Props) => {
  const id = normalizeId(props.id, 'SmartCourse');

  const { loading, error, data } = useQuery<SmartCourseQuery>(
    smartCourseQuery,
    { variables: { id } },
  );

  if (!data && loading) return <CircularProgress />;
  if (error) return <GraphQLErrorDisplay error={error} />;
  if (data?.node?.__typename !== 'SmartCourse') return 'Not found :(';
  const course = data.node;

  return (
    <Container>
      <InfoBox marginY={2}>
        <Box>
          <b>Course: </b>
          <Link to={`/smartCourse/${course.id}`}>{course.title}</Link>{' '}
          {getLanguageName(course.language)}
        </Box>
        <Box>
          <b>Course ID:</b>
          <CopyableID>{course.id}</CopyableID>
        </Box>
        <Box>
          <b>Difficulty: </b>
          {course.difficulty}
        </Box>
      </InfoBox>
      <Box>
        <Typography variant="h3" component="h1">
          {course.title}
        </Typography>
        <Typography variant="h4">Lessons</Typography>
        <Divider />
      </Box>
      <Box marginY={2}>
        {course.lessons.map(
          lesson =>
            lesson && (
              <div key={lesson.id}>
                <Link to={`/smartCourse/lesson/${lesson.id}`}>
                  {lesson.numberInCourse} - {lesson.title}
                </Link>
              </div>
            ),
        )}
      </Box>
      <Box mt={4} mb={2}>
        <Typography variant="h4">Topics</Typography>
        <Divider />
      </Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {course.topics?.map(topic => (
          <Box key={topic} mr={1} mt={1}>
            <Chip variant="outlined" label={topic} />
          </Box>
        ))}
      </Box>
    </Container>
  );
};
