import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core';
import { Error } from '@material-ui/icons';
import React, { FC } from 'react';
import { useQuery } from 'react-query';
import fetch from 'isomorphic-fetch';

import { apiHostManager } from '../../apollo/client';

const BRANCH_API_HOST_BASE = process.env.BRANCH_API_HOST_BASE as
  | string
  | undefined;

const BRANCHES_URL = `https://branches.${BRANCH_API_HOST_BASE}`;

export const BranchSelect: FC = () => {
  const { isLoading, error, data } = useQuery<string[], any>(
    'repoData',
    async () => fetch(BRANCHES_URL).then(res => res.json()),
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Tooltip title={`Error loading branches: ${error.message}`}>
        <IconButton aria-label="error">
          <Error />
        </IconButton>
      </Tooltip>
    );
  }

  if (!data) return null;

  return (
    <FormControl>
      <InputLabel id="branch-select">Branch</InputLabel>
      <Select
        labelId="branch-select"
        value={apiHostManager.getCurrentBranch()}
        onChange={evt => {
          // set the branch as a query URL and refresh the page, so all data match what's returned by this branch API
          apiHostManager.setBranchAndReload(evt.target.value as string);
        }}
      >
        <MenuItem value={'main'}>
          <em>main</em>
        </MenuItem>
        {data.map(branch => (
          <MenuItem value={branch} key={branch}>
            {branch}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
