import { StudyLanguage } from '../../generated/graphql';

export const languages = {
  [StudyLanguage.En]: '🇬🇧 English',
  [StudyLanguage.Fr]: '🇫🇷 French',
  [StudyLanguage.Es]: '🇪🇸 Spanish',
  [StudyLanguage.De]: '🇩🇪 German',
  [StudyLanguage.It]: '🇮🇹 Italian',
};

export const getLanguageName = (language: StudyLanguage | undefined) => {
  return language ? languages[language] : '❓ Unknown';
};
