import queryString from 'query-string';
import { getApiRegion, setApiRegion } from '../persistentStorage';
import { setLocationQueryParamsAndReload } from '../util';

const CHINA_API_HOST = process.env.API_HOST_CN as string;
const GLOBAL_API_HOST = process.env.API_HOST as string;
const BRANCH_API_HOST_BASE = process.env.BRANCH_API_HOST_BASE as
  | string
  | undefined;

export class ApiHostManager {
  private isChina: boolean = false;

  private branchOverride: string | undefined;

  constructor() {
    const apiRegion = getApiRegion();
    if (apiRegion) {
      this.isChina = apiRegion === 'china';
    }
    this.updateRegion();

    // if the URL has a ?branch=xxx param, use that as the branch override value
    if (typeof window !== 'undefined') {
      const { branch } = queryString.parse(window.location.search);
      if (branch && typeof branch === 'string') {
        this.branchOverride = branch;
      }
    }
  }

  getApiHost() {
    if (this.branchOverride) {
      return `https://${this.branchOverride}.${BRANCH_API_HOST_BASE}`;
    }
    return this.isChina ? CHINA_API_HOST : GLOBAL_API_HOST;
  }

  /** Check connectivity and update base url if needed */
  async updateRegion(): Promise<void> {
    const [hasChinaConnectivity, hasGlobalConnectivity] = await Promise.all([
      this.checkRegionConnectivityAndUpdate(CHINA_API_HOST, true),
      this.checkRegionConnectivityAndUpdate(GLOBAL_API_HOST, false),
    ]);
    if (hasChinaConnectivity && !hasGlobalConnectivity) this.setIsChina(true);
    if (hasGlobalConnectivity && !hasChinaConnectivity) this.setIsChina(false);
  }

  setBranchAndReload(branch: string): void {
    setLocationQueryParamsAndReload({ branch });
  }

  getCurrentBranch(): string {
    return this.branchOverride ?? 'main';
  }

  private async checkRegionConnectivityAndUpdate(
    apiBase: string,
    isChina: boolean,
  ): Promise<boolean> {
    const isGlobal = !isChina;
    try {
      const res = await fetch(`${apiBase}/location`).then(raw => raw.json());
      if (res && typeof res.global === 'boolean') {
        if (isGlobal === res.global) {
          this.setIsChina(isChina);
        }
        return true;
      }
    } catch (err) {
      // it's expected that this will fail when going through the firewall
      // eslint-disable-next-line no-console
      console.warn(err);
    }
    return false;
  }

  private setIsChina(isChina: boolean) {
    if (isChina !== this.isChina) {
      this.isChina = isChina;
      // persist the result so we can use this straight-away next time the page is loaded
      setApiRegion(isChina ? 'china' : 'global');
    }
  }
}
