import gql from 'graphql-tag';
import * as React from 'react';
import { useMutation } from '@apollo/react-hooks';
import {
  Typography,
  TableBody,
  Table,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Button,
  TableHead,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import {
  AdminEnhancementsRowEnhancementFragment,
  AdminDeleteDictionaryEnhancementMutation,
  AdminDeleteDictionaryEnhancementMutationVariables,
} from '../../../generated/graphql';
import { ConfirmationDialog } from '../../ConfirmationDialog';

export interface TranslationsRowProps {
  enhancement: AdminEnhancementsRowEnhancementFragment;
}

export const EnhancementsRow = (props: TranslationsRowProps) => {
  const { enhancement } = props;
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(
    false,
  );
  const [deleteEnhancement, { loading }] = useMutation<
    AdminDeleteDictionaryEnhancementMutation,
    AdminDeleteDictionaryEnhancementMutationVariables
  >(deleteSenseEnhancementMutation);

  const onDeleteConfirmed = () => {
    setDeleteConfirmationOpen(false);
    deleteEnhancement({
      variables: {
        input: {
          id: enhancement.id,
        },
      },
    });
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setCollapseOpen(!collapseOpen)}
          >
            {collapseOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {enhancement.source}
        </TableCell>
        <TableCell align="left">
          {enhancement.__typename === 'TranslationsDictionaryEnhancement'
            ? 'Translations'
            : 'Unknown'}
        </TableCell>
        <TableCell align="right">
          <Button
            onClick={() => setDeleteConfirmationOpen(true)}
            disabled={loading}
          >
            Delete
          </Button>
          <ConfirmationDialog
            open={deleteConfirmationOpen}
            title="Are you sure?"
            body="Removing an enhancement will remove all data this enhancement added, are you sure you want to do this?"
            onCancel={() => setDeleteConfirmationOpen(false)}
            onConfirm={() => onDeleteConfirmed()}
          ></ConfirmationDialog>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Translations
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Language</TableCell>
                    <TableCell align="left">Translations</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {enhancement.translations.map(translation => (
                    <TableRow key={`${translation.language}`}>
                      <TableCell component="th" scope="row">
                        {translation.language}
                      </TableCell>
                      <TableCell>
                        {translation.translations.join(', ')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export const adminEnhancementsRowEnhancementFragment = gql`
  fragment AdminEnhancementsRowEnhancement on DictionaryEnhancementInterface {
    id
    source
    ... on TranslationsDictionaryEnhancement {
      translations {
        language
        translations
      }
    }
  }
`;

const deleteSenseEnhancementMutation = gql`
  mutation adminDeleteDictionaryEnhancement(
    $input: AdminDeleteDictionaryEnhancementInput!
  ) {
    adminDeleteDictionaryEnhancement(input: $input) {
      clientMutationId
      sense {
        id
        adminEnhancements {
          id
          ...AdminEnhancementsRowEnhancement
        }
      }
    }
  }
  ${adminEnhancementsRowEnhancementFragment}
`;
