import React, { useEffect, useState } from 'react';
import { makeStyles, Paper, Tab, Tabs } from '@material-ui/core';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';

import {
  GetAdminPromoCodesQuery,
  GetAdminPromoCodesQueryVariables,
  PromoCodeSourceKind,
} from '../../../generated/graphql';
import { CreatePromoCodeDialogButton } from './CreatePromoCodeDialogButton';
import { GraphQLErrorDisplay } from '../../GraphQLErrorDisplay';
import { PromoCode, promoCodeInfoFragment } from './PromoCode';
import { CreatePromoCodeBatchDialogButton } from './CreatePromoCodeBatchDialogButton';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    marginBottom: theme.spacing(2),
    flexDirection: 'row',
    display: 'flex',
  },
  promoCodeContainer: {
    marginTop: theme.spacing(1),
  },
}));

const getAdminPromoCodes = gql`
  query getAdminPromoCodes(
    $after: String
    $first: Int
    $sourceKind: PromoCodeSourceKind
  ) {
    admin {
      adminPromoCodesLookup(
        first: $first
        after: $after
        sourceKind: $sourceKind
      ) {
        edges {
          node {
            ...promoCodeInfo
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${promoCodeInfoFragment}
`;

const TABS = [PromoCodeSourceKind.Admin];

export const PromoCodeLookup = () => {
  const styles = useStyles();
  const [tabIndex, setTabIndex] = useState(0);

  const [getData, { data, error }] = useLazyQuery<
    GetAdminPromoCodesQuery,
    GetAdminPromoCodesQueryVariables
  >(getAdminPromoCodes, { variables: { first: 100 } });

  useEffect(() => {
    getData({
      variables: {
        sourceKind: TABS[tabIndex],
      },
    });
  }, [tabIndex]);

  return (
    <>
      <h1>Promo Codes</h1>
      <div className={styles.buttonContainer}>
        <CreatePromoCodeDialogButton />
        <CreatePromoCodeBatchDialogButton />
      </div>

      <Paper square>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_evt, newVal) => setTabIndex(newVal)}
        >
          {TABS.map(tab => (
            <Tab key={tab} label={tab} />
          ))}
        </Tabs>
      </Paper>
      {data?.admin?.adminPromoCodesLookup.edges?.map(
        edge =>
          edge?.node && (
            <div key={edge.node.id} className={styles.promoCodeContainer}>
              <PromoCode info={edge.node} />
            </div>
          ),
      )}
      <GraphQLErrorDisplay error={error} />
    </>
  );
};
