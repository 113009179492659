import * as React from 'react';

import { Router } from '@reach/router';
import { DictionarySensePage } from '../components/subpages/DictionarySensePage';
import { DictionarySearchPage } from '../components/subpages/DictionarySearchPage';
import { Route } from '../components/Route';
import IndexLayout from '../components/common/Layout';

const DictionaryPage = () => {
  return (
    <IndexLayout>
      <Router>
        <Route path="/dictionary" component={DictionarySearchPage} />
        <Route path="/dictionary/sense/:id" component={DictionarySensePage} />
      </Router>
    </IndexLayout>
  );
};

export default DictionaryPage;
