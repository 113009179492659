import * as React from 'react';
import { Link } from 'gatsby';
import { useQuery } from '@apollo/react-hooks';
import { Router } from '@reach/router';
import gql from 'graphql-tag';
import { makeStyles } from '@material-ui/core';

import {
  IndexViewerQueryQuery,
  AdminPermission,
  StudyLanguage,
} from '../generated/graphql';
import Page from '../components/common/Page';
import Container from '../components/common/Container';
import IndexLayout from '../components/common/Layout';
import { Route } from '../components/Route';
import { languages } from '../components/common/getLanguageName';

const indexQuery = gql`
  query indexViewerQuery {
    viewer {
      admin {
        id
        permissions
      }
    }
  }
`;

const useStyles = makeStyles({
  ul: {
    margin: 0,
    padding: 0,
    paddingTop: 8,
    paddingBottom: 8,
  },
  li: {
    padding: 0,
    paddingLeft: 24,
    listStyle: 'none',
  },
});

const renderLanguageListItems = (styles: Record<'li', string>) =>
  (Object.keys(languages) as StudyLanguage[]).map(lang => (
    <li className={styles.li} key={lang}>
      <Link to={`/smartCourse?lang=${lang.toLocaleLowerCase()}`}>
        {languages[lang]}
      </Link>
    </li>
  ));

const Index = () => {
  const { loading, error, data } = useQuery<IndexViewerQueryQuery>(indexQuery);
  const styles = useStyles();
  if (loading) return <p>Loading...</p>;
  if (error) {
    return <p>{error.message} - Try reloading the website</p>;
  }

  const permissions = data?.viewer?.admin?.permissions || [];
  return (
    <>
      {permissions.includes(AdminPermission.ReadCourseware) && (
        <Link to="/courseware">Courseware</Link>
      )}
      <br />
      {permissions.includes(AdminPermission.ReadCourseware) && (
        <>
          <ul className={styles.ul}>
            <div>Smart Courses</div>
            {renderLanguageListItems(styles)}
          </ul>
        </>
      )}
      {permissions.includes(AdminPermission.ReadDictionary) && (
        <Link to="/dictionary">Dictionary</Link>
      )}
      <br />
      {permissions.includes(AdminPermission.ReadUserData) && (
        <Link to="/userLookup">User Lookup</Link>
      )}
      <br />
      {permissions.includes(AdminPermission.ManageAdmins) && (
        <Link to="/adminLookup">Admin Lookup</Link>
      )}
      <br />
      {permissions.includes(AdminPermission.ReadClassBooking) && (
        <Link to="/bookings">Bookings</Link>
      )}
      <br />
      {permissions.includes(AdminPermission.ManagePromoCodes) && (
        <Link to="/promoCodeLookup">Promo Codes</Link>
      )}
    </>
  );
};

const IndexPage = () => (
  <IndexLayout>
    <Page>
      <Container>
        <Router primary>
          <Route path="/" component={Index} />
        </Router>
      </Container>
    </Page>
  </IndexLayout>
);

export default IndexPage;
