import React, { FunctionComponent, useEffect, useState } from 'react';
import gql from 'graphql-tag';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/react-hooks';
import { fromGlobalId } from 'graphql-relay';
import flatten from 'lodash.flatten';
import { Link } from 'gatsby';

import { Box, Chip, Fab, IconButton, Typography } from '@material-ui/core';
import { Add, AddBox, FileCopy } from '@material-ui/icons';
import { exists, capitalize } from '../../util';
import { Dialog, dialogFragment } from '../dialog/Dialog';
import { LeftHighlightBorderContainer } from '../LeftHighlightBorderContainer';
import {
  CoursewareReviewSummaryContentItem,
  coursewareReviewSummaryContentItemFragment,
} from '../courseware/CoursewareReviewSummaryContentItem';
import {
  LoadSummaryQuery,
  LoadConversationQuery,
  LoadSectionInfoQuery,
  SectionNavQuery,
  AddVocabInfoToSectionQuery,
} from '../../generated/graphql';
import { getSectionMode, setSectionMode } from '../../persistentStorage';
import { LanguageToggleContextContainer } from '../LanguageToggleContextContainer';
import { normalizeId } from '../../normalizeId';
import { GraphQLErrorDisplay } from '../GraphQLErrorDisplay';
import { VocabSuggestionChip } from '../VocabSuggestionChip';
import { AddVocabToLessonDialog } from '../AddVocabToLessonDialog';
import { withTextSelection } from '../withTextSelection';

type Mode = 'conversation' | 'summary';

interface CoursewareSectionPageProps {
  id: string;
}

const conversationQuery = gql`
  query LoadConversation($id: ID!) {
    node(id: $id) {
      id
      ... on CoursewareStudySection {
        dialog {
          ...dialogFragment
        }
      }
    }
  }

  ${dialogFragment}
`;

const summaryQuery = gql`
  query LoadSummary($id: ID!) {
    node(id: $id) {
      id
      ... on CoursewareStudySection {
        reviewSummaryContents {
          ...coursewareReviewSummaryContentItemFragment
        }
      }
    }
  }

  ${coursewareReviewSummaryContentItemFragment}
`;

const sectionInfoQuery = gql`
  query LoadSectionInfo($id: ID!) {
    node(id: $id) {
      id
      ... on CoursewareStudySection {
        title
        description
        sectionType
        lesson {
          id
          title
          numberInCourse
          course {
            id
            title
          }
        }
        vocabSuggestions
      }
    }
  }
`;

const addVocabInfoToSectionQuery = gql`
  query AddVocabInfoToSection($id: ID!) {
    node(id: $id) {
      id
      ... on CoursewareStudySection {
        lesson {
          id
        }
        vocabSuggestions
      }
    }
  }
`;

const sectionNavQuery = gql`
  query SectionNav {
    courses {
      id
      lessons {
        id
        sections {
          id
          sectionType
        }
      }
    }
  }
`;

const SectionScreen = styled.div`
  width: 500px;
  border-radius: 12px;
  background: white;
`;
const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const InfoBox = styled.div`
  border: 1px solid black;
  padding: 16px;
  margin-bottom: 16px;
`;
const InfoItem = styled.div``;
const BottomNavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 16px 0;
  font-size: 14px;
`;

const ModeToggleContainer = styled.div`
  display: flex;
  border: 1px solid #eee;
  margin-bottom: 20px;
`;

interface ToggleButtonProps {
  isSelected: boolean;
}
const ToggleButton = styled.button<ToggleButtonProps>(
  ({ isSelected }) => `
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px 30px;
  flex-grow: 1;
  background: ${isSelected ? '#663399' : 'none'};
  color: ${isSelected ? 'white' : '#333'};
`,
);

const defaultMode = getSectionMode();

const CoursewareSectionpage: FunctionComponent<CoursewareSectionPageProps> = props => {
  const [mode, setMode] = useState(defaultMode);
  const id = normalizeId(props.id, 'CoursewareStudySection');

  const renderConversationContents = () => {
    const { loading, error, data } = useQuery<LoadConversationQuery>(
      conversationQuery,
      {
        variables: { id },
      },
    );
    if (error) return <GraphQLErrorDisplay error={error} />;

    if (
      !data ||
      !data.node ||
      data.node.__typename !== 'CoursewareStudySection' ||
      !data.node.dialog ||
      data.node.id !== id
    ) {
      if (loading) return <p>Loading...</p>;
      return <p>Not found :(</p>;
    }

    return (
      <LeftHighlightBorderContainer color="purple">
        <Dialog {...data.node.dialog} />
      </LeftHighlightBorderContainer>
    );
  };

  const renderSummaryContents = () => {
    const { loading, error, data } = useQuery<LoadSummaryQuery>(summaryQuery, {
      variables: { id },
    });
    if (error) return <GraphQLErrorDisplay error={error} />;

    if (
      !data ||
      !data.node ||
      data.node.__typename !== 'CoursewareStudySection' ||
      !data.node.reviewSummaryContents ||
      data.node.id !== id
    ) {
      if (loading) return <p>Loading...</p>;
      return <p>Not found :(</p>;
    }

    return (
      <>
        {data.node.reviewSummaryContents.map((item: any, i: number) => (
          <CoursewareReviewSummaryContentItem {...item} key={i} />
        ))}
      </>
    );
  };

  const renderNav = () => {
    const { loading, error, data } = useQuery<SectionNavQuery>(sectionNavQuery);
    if (error) return <GraphQLErrorDisplay error={error} />;
    if (loading) return <BottomNavContainer>Loading...</BottomNavContainer>;

    // force typescript to be sane
    const flatSections = flatten(
      flatten(
        data?.courses?.map(course =>
          course?.lessons.map(lesson => lesson.sections),
        ) || [],
      ).filter(exists),
    );

    const curSectionIndex = flatSections.findIndex(
      section => section.id === id,
    );
    const nextId =
      curSectionIndex < flatSections.length - 1 &&
      flatSections[curSectionIndex + 1].id;
    const prevId = curSectionIndex > 0 && flatSections[curSectionIndex - 1].id;

    const curSectionType = flatSections[curSectionIndex].sectionType;
    const nextOfType = flatSections
      .slice(curSectionIndex + 1)
      .find(section => section.sectionType === curSectionType);

    return (
      <BottomNavContainer>
        <Link to="/courseware">Courseware</Link>
        {prevId && (
          <Link to={`/courseware/section/${prevId}`}>Previous Page</Link>
        )}
        {nextId && <Link to={`/courseware/section/${nextId}`}>Next Page</Link>}
        {nextOfType ? (
          <Link to={`/courseware/section/${nextOfType.id}`}>
            Next {capitalize(curSectionType)}
          </Link>
        ) : null}
      </BottomNavContainer>
    );
  };

  const renderSectionInfo = () => {
    const { loading, error, data } = useQuery<LoadSectionInfoQuery>(
      sectionInfoQuery,
      { variables: { id } },
    );
    if (error) return <GraphQLErrorDisplay error={error} />;

    if (
      !data ||
      !data.node ||
      data.node.__typename !== 'CoursewareStudySection' ||
      data.node.id !== id
    ) {
      if (loading) return <InfoBox>Loading...</InfoBox>;
      return <p>Not found :(</p>;
    }
    const sectionInfo = data.node;

    return (
      <>
        <InfoBox>
          <InfoItem>
            <b>Course:</b>{' '}
            <Link to={`/courseware`}>{sectionInfo.lesson.course.title}</Link>
          </InfoItem>
          <InfoItem>
            <b>Lesson:</b>{' '}
            <Link to={`/courseware/lesson/${sectionInfo.lesson.id}`}>
              {sectionInfo.lesson.numberInCourse} - {sectionInfo.lesson.title}
            </Link>
          </InfoItem>
          <InfoItem>
            <b>Lesson ID:</b> {fromGlobalId(sectionInfo.lesson.id).id}
          </InfoItem>
          <InfoItem>
            <b>Section:</b> {sectionInfo.title}
          </InfoItem>
          <InfoItem>
            <b>Section ID:</b> {fromGlobalId(sectionInfo.id).id}
            <IconButton
              aria-label="copy"
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(fromGlobalId(sectionInfo.id).id);
              }}
            >
              <FileCopy fontSize="small" />
            </IconButton>
          </InfoItem>
          {/* TODO: put back section.subtitle after adding it to the API */}
          <InfoItem>
            <b>Section description:</b> {sectionInfo.description}
          </InfoItem>
        </InfoBox>
        <h1>{sectionInfo.title}</h1>
      </>
    );
  };

  const onUpdateMode = (newMode: Mode) => {
    setSectionMode(newMode);
    setMode(newMode);
  };

  return (
    <Container>
      <SectionScreen>
        {renderNav()}
        {renderSectionInfo()}
        <LanguageToggleContextContainer>
          <ModeToggleContainer>
            <ToggleButton
              isSelected={mode === 'summary'}
              onClick={() => onUpdateMode('summary')}
            >
              Summary
            </ToggleButton>
            <ToggleButton
              isSelected={mode === 'conversation'}
              onClick={() => onUpdateMode('conversation')}
            >
              Conversation
            </ToggleButton>
          </ModeToggleContainer>
          {mode === 'summary' && renderSummaryContents()}
          {mode === 'conversation' && renderConversationContents()}
        </LanguageToggleContextContainer>
        <Box>
          <WrappedAddVocabSection sectionId={id} />
        </Box>
      </SectionScreen>
    </Container>
  );
};

interface AddVocabSectionProps {
  sectionId: string;
  textSelection?: string;
}

const AddVocabSection: React.FC<AddVocabSectionProps> = ({
  sectionId,
  textSelection,
}) => {
  const [vocabTaggingDialogOpen, setVocabTaggingDialogOpen] = React.useState(
    false,
  );

  const [
    vocabTaggingWordSuggestion,
    setVocabTaggingWordSuggestion,
  ] = React.useState<string | undefined>();

  const onSuggestionPressed = (suggestion: string) => {
    setVocabTaggingWordSuggestion(suggestion);
    setVocabTaggingDialogOpen(true);
  };

  const onVocabTaggingDialogClose = () => {
    setVocabTaggingDialogOpen(false);
    setVocabTaggingWordSuggestion(undefined);
  };

  useEffect(() => {
    if (vocabTaggingDialogOpen) return;

    setVocabTaggingWordSuggestion(textSelection);
  }, [textSelection]);

  const { error, data } = useQuery<AddVocabInfoToSectionQuery>(
    addVocabInfoToSectionQuery,
    { variables: { id: sectionId } },
  );
  if (error) return <GraphQLErrorDisplay error={error} />;

  const sectionInfo =
    data?.node?.__typename === 'CoursewareStudySection' ? data.node : undefined;

  if (!sectionInfo) return null;

  return (
    <Box mt={4}>
      <Typography variant="h2">Tag Vocabulary</Typography>
      <Box mt={1} display="flex" flexDirection="row" flexWrap="wrap">
        {sectionInfo?.vocabSuggestions?.map(suggestion => (
          <Box key={suggestion} mr={1} mt={1}>
            <VocabSuggestionChip
              suggestion={suggestion}
              onClick={onSuggestionPressed}
            ></VocabSuggestionChip>
          </Box>
        ))}
        <Box mr={1} mt={1}>
          <Chip
            icon={<AddBox />}
            label="add new"
            onClick={() => setVocabTaggingDialogOpen(true)}
          />
        </Box>
      </Box>
      <AddVocabToLessonDialog
        open={vocabTaggingDialogOpen}
        onClose={onVocabTaggingDialogClose}
        lessonId={sectionInfo?.lesson.id}
        searchWord={vocabTaggingWordSuggestion}
      />
      {textSelection && (
        <Box position="fixed" right="32px" bottom="32px">
          <Fab
            variant="extended"
            onClick={() => setVocabTaggingDialogOpen(true)}
          >
            <Add />
            {`Tag "${textSelection}"`}
          </Fab>
        </Box>
      )}
    </Box>
  );
};

const WrappedAddVocabSection = withTextSelection(AddVocabSection);

export default CoursewareSectionpage;
