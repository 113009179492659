import * as React from 'react';
import { Link } from 'gatsby';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Typography,
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import flatten from 'lodash.flatten';

import Container from '../common/Container';
import {
  CoursewareLessonQuery,
  LessonNavQuery,
  VocabLocationChipDataFragment,
} from '../../generated/graphql';
import { normalizeId } from '../../normalizeId';
import { GraphQLErrorDisplay } from '../GraphQLErrorDisplay';
import { exists } from '../../util';
import { AddVocabToLessonDialog } from '../AddVocabToLessonDialog';
import {
  vocabLocationChipDataFragment,
  VocabLocationChip,
} from '../VocabLocationChip';
import { VocabSuggestionChip } from '../VocabSuggestionChip';
import { removeVocabLocationMutation } from './SmartCoursePage/SmartCourseLessonPage';

const coursewareLessonQuery = gql`
  query CoursewareLesson($id: ID!) {
    node(id: $id) {
      ... on CoursewareLesson {
        id
        title
        numberInCourse
        course {
          id
          title
          cefrLevel
        }
        sections {
          id
          title
        }
        vocabLocations {
          ...vocabLocationChipData
          isSupplemental
        }
        vocabSuggestions
      }
    }
  }
  ${vocabLocationChipDataFragment}
`;

const lessonNavQuery = gql`
  query LessonNav {
    courses {
      id
      lessons {
        id
      }
    }
  }
`;

const renderLessonNav = (lessonId: string) => {
  const { loading, error, data } = useQuery<LessonNavQuery>(lessonNavQuery);
  if (error) return <GraphQLErrorDisplay error={error} />;
  if (loading || !data?.courses) return null;
  const allLessonIds = flatten(
    data?.courses.map(course => course?.lessons.map(lesson => lesson.id)),
  ).filter(exists);
  const lessonIndex = allLessonIds.indexOf(lessonId);
  if (lessonIndex === -1) return null;
  const nextLessonLink =
    lessonIndex < allLessonIds.length - 1 ? (
      <Link to={`/courseware/lesson/${allLessonIds[lessonIndex + 1]}`}>
        next →
      </Link>
    ) : null;
  const prevLessonLink =
    lessonIndex > 0 ? (
      <Box marginRight={2} display="inline-block">
        <Link to={`/courseware/lesson/${allLessonIds[lessonIndex - 1]}`}>
          ← previous
        </Link>
      </Box>
    ) : null;
  return (
    <Box marginTop={2} fontSize="12px">
      {prevLessonLink}
      {nextLessonLink}
    </Box>
  );
};

interface CoursewareLessonPageProps {
  id: string;
}

const CoursewareLessonPage = (props: CoursewareLessonPageProps) => {
  const id = normalizeId(props.id, 'CoursewareLesson');

  const [vocabTaggingDialogOpen, setVocabTaggingDialogOpen] = React.useState(
    false,
  );

  const [
    vocabTaggingWordSuggestion,
    setVocabTaggingWordSuggestion,
  ] = React.useState<string | undefined>();

  const onSuggestionPressed = (suggestion: string) => {
    setVocabTaggingWordSuggestion(suggestion);
    setVocabTaggingDialogOpen(true);
  };

  const onVocabTaggingDialogClose = () => {
    setVocabTaggingDialogOpen(false);
    setVocabTaggingWordSuggestion(undefined);
  };

  const { loading, error, data, refetch } = useQuery<CoursewareLessonQuery>(
    coursewareLessonQuery,
    { variables: { id } },
  );

  const [removeVocabLocation] = useMutation(removeVocabLocationMutation);
  const onPressDeleteVocabLocation = async (
    vocab: VocabLocationChipDataFragment,
  ) => {
    await removeVocabLocation({ variables: { id: vocab.id } });
    refetch();
  };

  const lessonNav = renderLessonNav(id);

  if (!data && loading) return <CircularProgress />;
  if (error) return <GraphQLErrorDisplay error={error} />;

  if (data?.node?.__typename !== 'CoursewareLesson') return 'Not found :(';
  const lesson = data.node;

  return (
    <Container>
      <Typography variant="h3" component="h1">
        {lesson.title}
      </Typography>
      <Box marginY={2}>
        <Link to="/courseware">{lesson.course.title}</Link>
        <br />
        Lesson {lesson.numberInCourse}
        <br />
        {lessonNav}
      </Box>
      <Box>
        <Typography variant="h2">Sections</Typography>
        <Divider />
      </Box>
      <Box marginY={2}>
        {lesson.sections.map(
          section =>
            section && (
              <div key={section.id}>
                <Link to={`/courseware/section/${section.id}`}>
                  {section.title}
                </Link>
              </div>
            ),
        )}
      </Box>
      <Box mt={4} mb={2}>
        <Typography variant="h2">Vocabulary</Typography>
        <Divider />
      </Box>
      <Box>
        <Typography variant="subtitle1">Tagged Vocabulary</Typography>
      </Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {lesson.vocabLocations
          ?.filter(vocab => !vocab.isSupplemental)
          .map(vocab => (
            <Box key={vocab.id} mr={1} mt={1}>
              <VocabLocationChip
                onDelete={onPressDeleteVocabLocation}
                data={vocab}
              />
            </Box>
          ))}
        <Box mr={1} mt={1}>
          <Chip
            variant="outlined"
            icon={<AddBoxIcon />}
            label="add new"
            onClick={() => setVocabTaggingDialogOpen(true)}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <Box mt={4} mb={2}>
          <Typography variant="subtitle1">Suggested Vocab</Typography>
          <Divider />
        </Box>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          {lesson.vocabSuggestions?.map(suggestion => (
            <Box key={suggestion} mr={1} mt={1}>
              <VocabSuggestionChip
                onClick={onSuggestionPressed}
                suggestion={suggestion}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <AddVocabToLessonDialog
        open={vocabTaggingDialogOpen}
        onClose={onVocabTaggingDialogClose}
        lessonId={lesson.id}
        searchWord={vocabTaggingWordSuggestion}
      />
    </Container>
  );
};

export default CoursewareLessonPage;
