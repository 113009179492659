import {
  FormControl,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import React from 'react';

import { StudyLanguage } from '../../../generated/graphql';
import { getLanguageName } from '../../common/getLanguageName';

const useStyles = makeStyles(() => ({
  formControl: {
    margin: 2,
    minWidth: 160,
    maxWidth: 160,
    marginTop: 32,
  },
}));

export const SmartCourseFilterLanguage = (props: {
  language: StudyLanguage;
  onChange: (
    event: React.ChangeEvent<{
      value: unknown;
    }>,
  ) => void;
}) => {
  const { language, onChange } = props;
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <Select
        labelId="studyLanguage"
        value={language}
        onChange={onChange}
        renderValue={lang => (
          <span>{getLanguageName(lang as StudyLanguage | undefined)}</span>
        )}
      >
        {Object.values(StudyLanguage).map(lang => (
          <MenuItem key={lang} value={lang}>
            <ListItemText primary={getLanguageName(lang)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
