import gql from 'graphql-tag';
import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  TableContainer,
  Typography,
  TableBody,
  Table,
  TableRow,
  TableCell,
  Box,
  TableHead,
} from '@material-ui/core';

import Container from '../../common/Container';
import {
  DictionarySensePageInformationQuery,
  DictionarySensePageInformationQueryVariables,
} from '../../../generated/graphql';
import {
  EnhancementsRow,
  adminEnhancementsRowEnhancementFragment,
} from './EnhancementsRow';

export interface DictionarySensePageProps {
  id: string;
}

export const DictionarySensePage = ({ id }: DictionarySensePageProps) => {
  const { data } = useQuery<
    DictionarySensePageInformationQuery,
    DictionarySensePageInformationQueryVariables
  >(dictionarySensePageInformationQuery, {
    variables: { id },
  });

  const sense =
    data?.node?.__typename === 'DictionarySense' ? data.node : undefined;

  return (
    <Container>
      <TableContainer>
        <Typography variant="h1">{sense?.word.word}</Typography>

        <Table aria-label="Sense Information">
          <TableBody>
            <TableRow>
              <TableCell align="left">Definition</TableCell>
              <TableCell align="left">{sense?.definition}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Part Of Speech</TableCell>
              <TableCell align="left">{sense?.pos}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer>
        <Box marginY={3}>
          <Typography variant="h2">Inflections</Typography>
        </Box>

        <Table aria-label="Inflections">
          <TableHead>
            <TableRow>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">Text</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sense?.inflections?.map(inflection => (
              <TableRow key={inflection.type}>
                <TableCell align="left">{inflection.type}</TableCell>
                <TableCell align="left">{inflection.text}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer>
        <Box marginY={3}>
          <Typography variant="h2">Examples</Typography>
        </Box>
        <Table aria-label="Examples">
          <TableBody>
            {sense?.examples?.length ? (
              sense?.examples?.map(example => (
                <TableRow key={example.text}>
                  <TableCell align="left">{example.text}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="left">
                  <Typography variant="body2" color="textSecondary">
                    No examples
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer>
        <Box marginY={3}>
          <Typography variant="h2">Enhancements</Typography>
        </Box>

        <Table aria-label="Enhancements">
          <TableBody>
            {sense?.adminEnhancements.length ? (
              sense?.adminEnhancements.map(enhancement => (
                <EnhancementsRow enhancement={enhancement} />
              ))
            ) : (
              <TableRow>
                <TableCell align="left">
                  <Typography variant="body2" color="textSecondary">
                    No enhancements
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

const dictionarySensePageInformationQuery = gql`
  query DictionarySensePageInformation($id: ID!) {
    node(id: $id) {
      ... on DictionarySense {
        id
        definition
        pos
        word {
          id
          word
        }
        inflections {
          text
          type
        }
        examples {
          text
        }
        translations {
          language
          word
          translation
        }
        adminEnhancements {
          id
          ...AdminEnhancementsRowEnhancement
        }
      }
    }
  }
  ${adminEnhancementsRowEnhancementFragment}
`;
