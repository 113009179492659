import { FormControl, makeStyles, TextField } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  formControl: {
    margin: 2,
    minWidth: 100,
    maxWidth: 100,
    marginTop: 10,
  },
}));

export const SmartCourseFilterTitle = (props: {
  filteredTitle: string;
  onChange: (
    event: React.ChangeEvent<{
      value: string;
    }>,
  ) => void;
}) => {
  const { filteredTitle, onChange } = props;
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <TextField
        id="outlined-basic"
        label="Title"
        variant="outlined"
        value={filteredTitle}
        onChange={onChange}
        inputRef={input => input && input.focus()}
      />
    </FormControl>
  );
};
