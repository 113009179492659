import {
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import React from 'react';
import { SmartCourseDifficulty } from '../../../generated/graphql';

const useStyles = makeStyles(() => ({
  formControl: {
    margin: 2,
    minWidth: 100,
    maxWidth: 100,
    marginTop: 10,
  },
}));

export const SmartCourseFilterDifficulty = (props: {
  filteredDifficulty: SmartCourseDifficulty[];
  onChange: (
    event: React.ChangeEvent<{
      value: unknown;
    }>,
  ) => void;
}) => {
  const { filteredDifficulty, onChange } = props;
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Filter</InputLabel>
      <Select
        labelId="smartCourseFilterDifficulty"
        multiple
        value={filteredDifficulty}
        onChange={onChange}
        input={<Input />}
        renderValue={selected => (selected as string[]).join(', ')}
      >
        {Object.values(SmartCourseDifficulty).map(name => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={filteredDifficulty.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
