import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React from 'react';
import { SmartCourseLoadSummaryQuery } from '../../../../generated/graphql';
import {
  CoursewareReviewSummaryContentItem,
  coursewareReviewSummaryContentItemFragment,
} from '../../../courseware/CoursewareReviewSummaryContentItem';
import { GraphQLErrorDisplay } from '../../../GraphQLErrorDisplay';

const smartCourseSectionSummaryQuery = gql`
  query SmartCourseLoadSummary($id: ID!) {
    node(id: $id) {
      id
      ... on SmartCourseSection {
        reviewSummaryContents {
          ...coursewareReviewSummaryContentItemFragment
        }
      }
    }
  }

  ${coursewareReviewSummaryContentItemFragment}
`;

interface SmartCourseSectionSummaryProps {
  sectionId: string;
}

export const SmartCourseSectionSummaryContent = (
  props: SmartCourseSectionSummaryProps,
) => {
  const { sectionId } = props;

  const { loading, error, data } = useQuery<SmartCourseLoadSummaryQuery>(
    smartCourseSectionSummaryQuery,
    {
      variables: { id: sectionId },
    },
  );
  if (error) return <GraphQLErrorDisplay error={error} />;

  if (
    !data ||
    !data.node ||
    data.node.__typename !== 'SmartCourseSection' ||
    !data.node.reviewSummaryContents ||
    data.node.id !== sectionId
  ) {
    if (loading) return <p>Loading...</p>;
    return <p>Not found :(</p>;
  }

  return (
    <>
      {data.node.reviewSummaryContents.map((item: any, i: number) => (
        <CoursewareReviewSummaryContentItem {...item} key={i} />
      ))}
    </>
  );
};
