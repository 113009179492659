import groupBy from 'lodash.groupby';
import queryString from 'query-string';

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const exists = <T>(obj: T | undefined | null): obj is T => !!obj;

export const formatDate = (dateStr: string) =>
  new Date(dateStr).toLocaleString();

export const getCurrentPathname = (): string | undefined =>
  (typeof window !== 'undefined' && window.location.pathname) || undefined;

export const setLocationQueryParamsAndReload = (params: {
  [key: string]: string;
}): void => {
  const currentParams = queryString.parse(window.location.search);
  window.location.search = queryString.stringify({
    ...currentParams,
    ...params,
  });
};

export const isServerRender = typeof window === 'undefined';

export const helloLevelToCefrLevel = (level: number): string => {
  if (level > 90) return 'C2';
  if (level > 72) return 'C1';
  if (level > 54) return 'B2';
  if (level > 36) return 'B1';
  if (level > 18) return 'A2';
  return 'A1';
};

export const helloLevelToCourseName = (level: number): string => {
  if (level > 90) return 'Advanced II';
  if (level > 72) return 'Advanced I';
  if (level > 54) return 'Intermediate II';
  if (level > 36) return 'Intermediate I';
  if (level > 18) return 'Beginner';
  return 'Foundation';
};

export const groupLevelsByCourseName = <T extends { number?: number }>(
  levels: Array<T | null>,
) =>
  groupBy(levels, level =>
    level?.number ? helloLevelToCourseName(level.number) : 'Unknown',
  );
