import { ApolloClient, DefaultOptions } from 'apollo-client';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ServerError } from 'apollo-link-http-common';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import fetch from 'isomorphic-fetch';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  IntrospectionResultData,
} from 'apollo-cache-inmemory';

import introspectionResult from '../introspection-result';
import { getAccessToken, clearAuthToken, refreshAccessToken } from '../auth';
import { getSecondaryLanguage } from '../persistentStorage';
import { ApiHostManager } from './ApiHostManager';
import { logger } from '../logger';
import { ErrorReason } from '../generated/graphql';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
  },
  query: {
    fetchPolicy: 'network-only',
  },
};

const createApolloCache = () => {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: introspectionResult as IntrospectionResultData,
  });

  return new InMemoryCache({
    addTypename: true,
    fragmentMatcher,
  });
};

export const apiHostManager = new ApiHostManager();

const httpLink = new HttpLink({
  uri: () => `${apiHostManager.getApiHost()}/graphql`,
  fetch,
  headers: {
    'X-Supported-Features':
      'HiddenTranscriptsDialogTopicSegment,ReadingAndTextCard,HiddenTranscripts,ImageAudioCard',
  },
});

const errorLink = onError(
  ({ networkError, graphQLErrors, response, operation }) => {
    logger.error('Apollo Error Link', {
      error: networkError || graphQLErrors?.[0],
      graphQLErrors,
      response,
      operation,
    });
    if (networkError && (networkError as ServerError).statusCode === 401) {
      clearAuthToken();
    }
  },
);

const refreshAccessTokenLink = onError(
  ({ networkError, operation, forward }) => {
    if (
      networkError &&
      'statusCode' in networkError &&
      'result' in networkError &&
      networkError.result.reason === ErrorReason.TokenExpired
    ) {
      return new Observable(observer => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        (async () => {
          try {
            await refreshAccessToken();
            const accessToken = getAccessToken();
            if (!accessToken) {
              throw networkError;
            }
            operation.setContext(({ headers = {} }) => ({
              headers: {
                ...headers,
                authorization: `Bearer ${accessToken}`,
              },
            }));

            forward(operation).subscribe(observer);
          } catch (error) {
            observer.error(error);
          }
        })();
      });
    }
    return undefined;
  },
);

const authLink = setContext(async (_, { headers }) => {
  const accessToken = getAccessToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
      'accept-language': `en-us, ${getSecondaryLanguage()}`,
    },
  };
});

const link = ApolloLink.from([
  authLink,
  errorLink,
  refreshAccessTokenLink,
  httpLink,
]);

export default new ApolloClient({
  link,
  cache: createApolloCache(),
  defaultOptions,
});
